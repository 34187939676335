'use strict'

/**
 * Constants file with the endpoints used by role management module to call a REST-services of the backend API.
 *
 * @type {number}
 *
 * @author Andres Correa
 * @since 22/07/2020 04:01 PM
 * @version 1.0.0
 */

/* Backend endpoints */
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL
export const GET_DOCUMENTS_SECTION = `${PUBLIC_BASE_URL}service/section/detail`
export const MANAGE_SECTION = `${PUBLIC_BASE_URL}service/section`
export const DELETE_DOCUMENT = `${PUBLIC_BASE_URL}service/document`
