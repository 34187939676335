<template>
  <v-dialog
    v-model="showModal"
    :persistent="persistent"
    max-width="510"
  >
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center pb-0 pt-8 subtitle-2 text-center">
        {{ titleModal }}
      </v-card-title>
      <ValidationObserver
        ref="obs"
        v-slot="{on, handleSubmit}"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
          >
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="settingDocumentsValidations.documentName"
                name="Nombre sección"
              >
                <v-text-field
                  id="fielDocumentName"
                  v-model="model.name"
                  :error-messages="errors"
                  label="Nombre documento"
                  type="text"
                  outlined
                  rounded
                  v-on="on"
                />
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="6"
          >
            <v-badge
              :value="hover"
              color="warning"
              content="Tamaño máximo 2 Gigabytes"
              top
              transition="slide-x-transition"
            >
              <v-hover v-model="hover">
                <app-text-field-with-validation
                  id="fielNameMaxSize"
                  v-model="model.maxFileSize"
                  :rules="settingDocumentsValidations.maximu_size"
                  :label="$t('size_file').toLowerCase()"
                  :label-input="$t('size_file')"
                  class="required"
                  name="fielNameMaxSize"
                  type="number"
                />
              </v-hover>
            </v-badge>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <app-check-box-with-validation
              id="requiredDocument"
              v-model="model.mandatory"
              :rules="settingDocumentsValidations.mandatory"
              :label="$t('required').toLowerCase()"
              :label-input="$t('required')"
              name="requiredDocument"
            />
          </v-col>
          <v-col cols="6">
            <app-check-box-with-validation
              id="encryptedDocument"
              v-model="model.encrypted"
              :rules="settingDocumentsValidations.encrypted"
              :label="$t('encrypted').toLowerCase()"
              :label-input="$t('encrypted')"
              name="encryptedDocument"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-select-with-validation
              id="extension"
              v-model="model.extension"
              :items="extensionCode"
              :label="$t('extension').toLowerCase()"
              :label-input="$t('extension')"
              :rules="settingDocumentsValidations.extension"
              item-text="code"
              item-value="code"
              name="extension"
            />
          </v-col>
        </v-row>
        <v-card-actions class="justify-center justify-space-around mt-4">
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="handleSubmit(agree)"
          >
            {{ agreeTextBottom }}
          </v-btn>
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="disagree"
          >
            {{ disagreeTextBottom }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import settingDocumentsValidations from './data/settingDocumentsValidations'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import { GET_EXTENSION, IDENTIFIER_EXTENSION } from './data/settingsDocumentsConstant'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'

export default {
  name: 'ModalDocuments',
  mixins: [handleErrorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
      description: 'Controla cuando muestra la modal'
    },
    titleModal: {
      type: String,
      required: true,
      description: 'Mensaje de la modal'
    },
    agreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para aceptar'
    },
    disagreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para cancelar'
    },
    persistent: {
      type: Boolean,
      default: true,
      description: 'La modal no se cierra al presionar fuera de ella'
    },
    dataEdit: {
      type: Object,
      default: null,
      description: 'Información del documento a modificar'
    }
  },
  data () {
    return {
      settingDocumentsValidations,
      dialog: false,
      extensionCode: [],
      hover: false,
      model: {
        name: '',
        maxFileSize: null,
        mandatory: false,
        encrypted: false,
        extension: ''
      }
    }
  },
  watch: {
    dataEdit () {
      if (this.dataEdit) {
        Object.assign(this.model, this.dataEdit)
      }
    }
  },
  created () {
    this.getExtensions()
  },
  beforeUpdate () {
    if (this.showModal && !this.dataEdit) {
      this.model = {
        name: '',
        maxFileSize: null,
        mandatory: false,
        encrypted: false,
        extension: ''
      }
      if (this.$refs.obs) {
        this.$refs.obs.reset()
      }
    }
  },
  methods: {
    agree () {
      this.$emit('resolve', this.model)
      this.model = {
        name: '',
        maxFileSize: null,
        mandatory: false,
        encrypted: false,
        extension: ''
      }
    },
    disagree () {
      this.$emit('resolve', false)
      this.model = {
        name: '',
        maxFileSize: null,
        mandatory: false,
        encrypted: false,
        extension: ''
      }
    },
    /**
     * This method get the list with the extension for the documents
     * Este método obtione la lista con las extensiones para los documentos
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 3:30 PM
     */
    getExtensions () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.get(`${GET_EXTENSION}/${IDENTIFIER_EXTENSION}`)
        .then((response) => {
          this.extensionCode = response.data
        }).catch((error) => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    }
  }
}
</script>
<i18n>
{
  "es": {
    "code": "Código",
    "extension": "Extensión",
    "required": "Requerido",
    "encrypted": "Cifrado",
    "size_file": "Tamaño en Megabytes"
  }
}
</i18n>
