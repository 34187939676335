'use strict'

/**
 * Constant for get extension file
 *
 * @type {number}
 *
 * @author Andres Correa
 * @since 30/07/2020 04:01 PM
 * @version 1.0.0
 */

/* Backend endpoints */
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL
export const GET_EXTENSION = `${PUBLIC_BASE_URL}service/general/code-description-enum`
export const IDENTIFIER_EXTENSION = 'ext'
