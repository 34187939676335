'use strict'

/**
 * Object with all validations of the legal area form inputs.
 *
 * @author Andres Correa
 * @since 12/05/2020 11:40 AM
 * @version 1.0.0
 */
export default {
  documentName: {
    required: true
  },
  maximu_size: {
    required: true,
    between: {
      min: 1,
      max: 2048
    }
  },
  encrypted: {
    required: false
  },
  extension: {
    required: true
  },
  mandatory: {
    require: false
  },
  codeDocument: {
    required: true
  }
}
