<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h3 class="subtitle-1 text-uppercase grey--text">
          {{ $route.params.projectInfo.tenderName }}
        </h3>
      </v-col>
      <v-col cols="3">
        <h4 class="subtitle-1 text-uppercase grey--text">
          {{ $t('stage_label') }}: {{ $route.params.infoStage.name }}
        </h4>
      </v-col>
      <v-col cols="3">
        <h4 class="subtitle-1 text-uppercase grey--text">
          {{ $t('section_label') }}: {{ $route.params.sectionInfo.name }}
        </h4>
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <ValidationObserver
      ref="obs"
      v-slot="{on, handleSubmit}"
    >
      <v-row no-gutters>
        <v-col cols="4">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="projectsSettingsDocumentsValidations.sectionName"
            name="Nombre sección"
          >
            <v-text-field
              id="fielNameSection"
              v-model="model.sectionData.name"
              :error-messages="errors"
              label="Nombre sección"
              type="text"
              outlined
              rounded
              v-on="on"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <app-text-field-with-validation
            id="mandatoryDocuments"
            v-model="model.sectionData.mandatoryDocuments"
            :rules="projectsSettingsDocumentsValidations.mandatory"
            :label="$t('mandatory_documents').toLowerCase()"
            :label-input="$t('mandatory_documents')"
            class="required"
            name="mandatoryDocuments"
            type="number"
          />
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-row>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('documents_label') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3 gray-color" />
    <v-row
      justify="start"
    >
      <v-col class="col-xl-3 col-md-6 col-lg-4">
        <v-btn
          large
          color="primary"
          rounded
          block
          @click="() => { showModalSaveDocument = true }"
        >
          {{ $t('add_stage') }}
        </v-btn>
        <span
          v-if="!validateRequiredDocuments"
          class="v-messages v-messages__message error--text pl-5"
        >
          {{ $t('notification_required') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          color="quaternary"
        >
          <app-generic-table
            ref="genericTable"
            :table-fields="$_tableHeaderMixin_generate"
            :actions="true"
            :data-load="concatDocuments"
            disable-pagination
            @edit-row="editDocument"
            @delete-row="deleteDocument"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-col cols="2">
        <v-btn
          color="primary lighten-1 white--text"
          class="mt-12 mr-4"
          block
          :disabled="!validateRequiredDocuments"
          @click="manageSectionWithDocuments"
        >
          {{ $t('save_and_finish') }}
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="gray_color lighten-1 white--text"
          class="mt-12 mr-4"
          block
          @click="toReturn"
        >
          {{ $t('exit') }}
        </v-btn>
      </v-col>
    </v-row>
    <settings-documents-modal
      :show-modal="showModalSaveDocument"
      title-modal="Crear documento"
      agree-text-bottom="Confirmar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveCreateDocument"
    />
    <settings-documents-modal
      ref="modalEdit"
      :show-modal="showModalEditDocument"
      :data-edit="documentEdition"
      title-modal="Editar documento"
      agree-text-bottom="Confirmar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveEditDocument"
    />
  </v-container>
</template>

<script>
import tableHeaderMixin from '@/mixins/tableHeaderMixin'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import SettingsDocumentsModal from './components/modal/SettingsDocumentsModal'
import projectsSettingsDocumentsValidations from './data/projectsSettingsDocumentsValidations'
import { ADD_COUNTER_REQUEST, SHOW_ALERT, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import projectsSettingsDocumentsHeader from './data/projectsSettingsDocumentsHeader'
import { MANAGE_SECTION, GET_DOCUMENTS_SECTION } from './data/projectsSettingsDocumentsConstant'
import AppGenericTable from '@/components/uiComponents/genericTable/AppGenericTable'

export default {
  name: 'ProjectSettingsDocuments',
  components: {
    AppGenericTable,
    SettingsDocumentsModal
  },
  mixins: [tableHeaderMixin, handleErrorMixin],
  beforeRouteEnter (to, from, next) {
    if (to.params.sectionInfo && to.params.sectionInfo.id) {
      next()
    } else {
      next({ name: 'projects' })
    }
  },
  data () {
    return {
      projectsSettingsDocumentsValidations,
      showModalSaveDocument: false,
      showModalEditDocument: false,
      columnOptions: projectsSettingsDocumentsHeader,
      documentsFromApi: [],
      documentEdition: {},
      model: {
        sectionData: {
          name: this.$route.params.sectionInfo.name,
          mandatoryDocuments: this.$route.params.sectionInfo.mandatoryDocuments
        },
        documentsToCreate: [],
        documentsToDelete: [],
        documentsToUpdate: []
      }
    }
  },
  computed: {
    /**
     * This computed property join 2 arrays, existing and new documents, to render in the tableGeneric
     * Esta propiedad computada une 2 arrays los ya existentes y los nuevos para que se rendericen en la tabla
     * @author Andres Correa
     * @version 1.0.0
     * @since 29/07/2020 09:30 AM
     */
    concatDocuments () {
      return this.documentsFromApi.concat(this.model.documentsToCreate)
    },
    /**
     * This computed property validates that the required documents are fulfilled
     * Esta propiedad computadad verifica que los documentos requeridos esten creados.
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 3:30 PM
     */
    validateRequiredDocuments () {
      return this.concatDocuments.filter(({ mandatory }) => mandatory).length >= this.model.sectionData.mandatoryDocuments
    }
  },
  created () {
    /**
     * This hook the vue build execute a method for get the existing documents
     * Esta propiedad computadad llama al método para obtener los documentos existentes.
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 3:30 PM
     */
    this.getDocumentsBySection()
  },
  methods: {
    /**
     * This method add new document to memory array
     * Este método agrega un nuevo documento al array documentsToCreate
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 29/07/2020 09:30 AM
     */
    addDocumentToList (newDocument) {
      this.model.documentsToCreate.push(newDocument)
    },
    /**
     * This method validates if the document is from the back or is in memory
     * if it is in memory it deletes from the array but adds it to the deleted array.
     * Este método valida si el documento es del back o si esta en memoria, si esta en memoria elimina
     * del array sino agrega al array de eliminados.
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 08:16 AM
     */
    deleteDocument (response) {
      if (response.id) {
        this.model.documentsToDelete.push(response.id)
        this.documentsFromApi = this.documentsFromApi.filter(iterator => iterator !== response)
        this.model.documentsToUpdate = this.model.documentsToUpdate.filter(iterator => iterator !== response)
      } else {
        this.model.documentsToCreate = this.model.documentsToCreate.filter(iterator => iterator !== response)
      }
    },
    /**
     * This method open modal of edit document
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 09:30 AM
     */
    editDocument (document) {
      this.documentEdition = document
      this.showModalEditDocument = true
    },
    /**
     * This method execute a endpoint for get the documents existing.
     * Este método ejecuta el endpoint para obtener los documentos existentes.
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 3:30 PM
     */
    getDocumentsBySection () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.get(GET_DOCUMENTS_SECTION, {
        params: { sectionId: this.$route.params.sectionInfo.id } })
        .then((response) => {
          this.documentsFromApi = response.data.documentResponseList
        }).catch((error) => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    },
    /**
     * This method received JSON with info for new document
     * Este método recibe la información del nuevo documento
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 09:30 AM
     */
    resolveCreateDocument (data) {
      this.showModalSaveDocument = false
      if (data) {
        const createInfo = {}
        Object.assign(createInfo, data)
        if (createInfo) {
          createInfo.sectionId = this.$route.params.sectionInfo.id
          this.addDocumentToList(createInfo)
        }
      }
    },
    /**
     * This method edit a document and add to documentsToUpdate array
     * Este método agrega el documento a array de editados y reemplaza en el origen para visualizar en la tabla
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 10:30 AM
     */
    resolveEditDocument (documentEdit) {
      this.showModalEditDocument = false
      if (documentEdit && documentEdit.id) {
        this.model.documentsToUpdate.push(documentEdit)
        let index = this.documentsFromApi.findIndex(document => document.id === documentEdit.id)
        if (index !== -1) {
          this.documentsFromApi.splice(index, 1, documentEdit)
        }
      } else if (documentEdit) {
        this.deleteDocument(this.documentEdition)
        this.model.documentsToCreate.push(documentEdit)
      }
      this.documentEdition = {}
    },
    /**
     * This method save a new document, updated a document or delete a document.
     * Esta método guarda un nuevo documento, actualiza un documento o elimina un documento.
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 3:30 PM
     */
    manageSectionWithDocuments () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.put(`${MANAGE_SECTION}/${this.$route.params.sectionInfo.id}`, this.model).then((response) => {
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('save_updated'),
          show: true
        })
        this.$router.push({ name: 'SettingsSections', params: { projectInfo: this.$route.params.projectInfo, infoStage: this.$route.params.infoStage } })
      }).catch((error) => {
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     * This method return to the view of settings sections
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 30/07/2020 10:30 AM
     */
    toReturn () {
      this.$router.push({
        name: 'SettingsSections', params: { projectInfo: this.$route.params.projectInfo, infoStage: this.$route.params.infoStage }
      })
    }
  }
}
</script>
<i18n src="./data/i18nMessage.json" />
