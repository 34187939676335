'use strict'

/**
 * Object column user table.
 *
 * @author Andres Correa
 * @since 23/07/2020 11:20 AM
 * @version 1.0.0
 */
export default [
  {
    text: 'column_table.name',
    align: 'left',
    sortable: true,
    value: 'name'
  },
  {
    text: 'column_table.extension',
    align: 'center',
    sortable: true,
    value: 'extension'
  },
  {
    text: 'column_table.mandatory',
    align: 'center',
    sortable: true,
    value: 'mandatory',
    format: 'yesOrNotFormat'
  },
  {
    text: 'column_table.max_file_size',
    align: 'center',
    sortable: true,
    value: 'maxFileSize',
    format: 'fileSizeFormat'
  },
  {
    text: 'column_table.encrypted',
    align: 'center',
    sortable: true,
    value: 'encrypted',
    format: 'yesOrNotFormat'
  }
]
